import { axios } from "../connection/ConnectionHandler";
import { fetchSalesShipments } from "./salesShipmentsActions";
import {
  APP_COOKIE_NAME,
  AUTH,
  PASS_COOKIE_NAME,
  SET_CURRENT_ROUTE,
  SET_PROFILE,
  USER_COOKIE_NAME,
  USER_ID_COOKIE_NAME,
} from "../constants";
import { API } from "../constants/api";
import { deleteCookie, getCookie, setCookie } from "../utils";
import { fetchNavCustomers, fetchNavUsers, fetchProducts, fetchUsers } from "./usersActions";
import { routes } from "../constants/routes";
import { fetchEquipmentConfigurations } from "./equipmentConfigurationsActions";
import { fetchInstalations } from "./instalationsActions";
import { fetchProjects } from "./projectsActions";
import { fetchImputHours } from "./ImputHoursActions";
import { fetchEquipments } from "./equipmentsActions";
import { fetchSatisfactions } from "./satisfactionsActions";
import { fetchAlerts } from "./alertsActions";
import { fetchSuggestions } from "./suggestionsActions";
import { fetchEstablishmentInfo } from "./establishmentInfoActions";
import { fetchExpenses } from "./expensesActions";
import { fetchOPCUA } from "./OPCUAActions";
import { fetchTimeRequests } from "./timeRequestActions";
import { fetchHolidayRequests } from "./holidayRequestActions";
import { fetchWorkShift } from "./workShiftsActions";
import { fetchLeave } from "./leavesActions";
import { fetchReasons } from "./reasonsActions";
import { fetchEquipmentTypes } from "./equipmentTypesActions";
import { fetchComponents } from "./componentsActions";
import { fetchComponentTypes } from "./componentTypesActions";
import { fetchMaintenances } from "./maintenancesActions";
import { fetchWorkOrders } from "./workOrdersActions";
import { fetchRoles } from "./rolesActions";

const rememberLogin = (username, password, remember) => {
  if (remember) {
    setCookie(USER_COOKIE_NAME, username, 30);
    setCookie(PASS_COOKIE_NAME, password, 30);
  } else {
    deleteCookie(USER_COOKIE_NAME, "");
    deleteCookie(PASS_COOKIE_NAME, "");
  }
};

/* PERSISTENCY CHECK */
export const checkToken = () => {
  return async (dispatch) => {
    const token = await getCookie(APP_COOKIE_NAME);
    const userId = await getCookie(USER_ID_COOKIE_NAME);
    if (token) {
      dispatch({ type: AUTH, payload: { auth: true, token, userId } });
    }
  };
};

export const fetchUserProfile = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.users.profile)
      .then((response) => {
        dispatch({ type: SET_PROFILE, payload: response.data });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const uploadImage = (data, url) => {
  return async (dispatch) => {
    const response = await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const deleteImage = (url) => {
  return async (dispatch) => {
    const response = await axios
      .delete(url)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const uploadDocument = (data, url) => {
  return async (dispatch) => {
    const response = await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const deleteDocument = (url) => {
  return async (dispatch) => {
    const response = await axios
      .delete(url)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

/* INITAL DATA LOAD */
export const loadInitialData = () => {
  return async (dispatch) => {
    dispatch(fetchEstablishmentInfo());
		dispatch(fetchSalesShipments());
    dispatch(fetchRoles());
    dispatch(fetchUsers());
    dispatch(fetchEquipmentConfigurations());
    dispatch(fetchInstalations());
    dispatch(fetchProjects());
    dispatch(fetchImputHours());
    dispatch(fetchEquipments());
    dispatch(fetchSatisfactions());
    dispatch(fetchAlerts());
    dispatch(fetchSuggestions());
    dispatch(fetchExpenses());
    dispatch(fetchOPCUA());
    dispatch(fetchHolidayRequests());
    dispatch(fetchTimeRequests());
    dispatch(fetchWorkShift());
    dispatch(fetchLeave());
    dispatch(fetchReasons());
    dispatch(fetchEquipmentTypes());
    dispatch(fetchComponents());
    dispatch(fetchComponentTypes());
    dispatch(fetchMaintenances());
    dispatch(fetchWorkOrders());
    dispatch(fetchProducts());
    dispatch(fetchNavUsers());
    dispatch(fetchNavCustomers());
  };
};

export const login = (username, password, remember) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.login, { username, password })
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(username, password, remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

export const check2FA = (data) => {
  return async (dispatch) => {
    const response = await axios
      .post(API.auth.check2FA, data)
      .then((response) => {
        if (response && response.data) {
          const { access_token } = response.data || {};
          rememberLogin(data.username, data.password, data.remember);
          dispatch({
            type: AUTH,
            payload: { auth: true, token: access_token },
          });
          dispatch({ type: SET_PROFILE, payload: response.data.resUser });
          dispatch({
            type: SET_CURRENT_ROUTE,
            payload: routes["home"],
          });
        }
        return response;
      })
      .catch((err) => err);
    return response;
  };
};
