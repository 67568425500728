import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_USERS_FORM,
  CLOSE_USERS_FORM,
  EDIT_SELECTED_USER,
  USERS_FORM_TOOGLE_LOADING,
  SET_USER_LIST,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
  ADD_NEW_EQUIPMENTCONFIGURATION,
  SET_EQUIPMENTCONFIGURATION_LIST,
  UPDATE_EQUIPMENTCONFIGURATION,
  DELETE_EQUIPMENTCONFIGURATION,
  OPEN_EQUIPMENTCONFIGURATION_FORM,
  CLOSE_EQUIPMENTCONFIGURATION_FORM,
  EDIT_SELECTED_EQUIPMENTCONFIGURATION,
  EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING,
  SET_INSTALATION_LIST,
  ADD_NEW_INSTALATION,
  UPDATE_INSTALATION,
  DELETE_INSTALATION,
  OPEN_INSTALATION_FORM,
  CLOSE_INSTALATION_FORM,
  EDIT_SELECTED_INSTALATION,
  INSTALATION_FORM_TOOGLE_LOADING,
  SET_PROJECT_LIST,
  ADD_NEW_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  OPEN_PROJECT_FORM,
  CLOSE_PROJECT_FORM,
  EDIT_SELECTED_PROJECT,
  PROJECT_FORM_TOOGLE_LOADING,
  SET_IMPUTHOUR_LIST,
  ADD_NEW_IMPUTHOUR,
  UPDATE_IMPUTHOUR,
  DELETE_IMPUTHOUR,
  OPEN_IMPUTHOUR_FORM,
  CLOSE_IMPUTHOUR_FORM,
  EDIT_SELECTED_IMPUTHOUR,
  IMPUTHOUR_FORM_TOOGLE_LOADING,
  SET_SINGUP_LIST,
  ADD_NEW_SINGUP,
  UPDATE_SINGUP,
  DELETE_SINGUP,
  OPEN_SINGUP_FORM,
  CLOSE_SINGUP_FORM,
  EDIT_SELECTED_SINGUP,
  SINGUP_FORM_TOOGLE_LOADING,
  SET_EQUIPMENT_LIST,
  ADD_NEW_EQUIPMENT,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  OPEN_EQUIPMENT_FORM,
  CLOSE_EQUIPMENT_FORM,
  EDIT_SELECTED_EQUIPMENT,
  EQUIPMENT_FORM_TOOGLE_LOADING,
  SET_NAVUSER_LIST,
  SET_INCIDENCE_LIST,
  ADD_NEW_INCIDENCE,
  UPDATE_INCIDENCE,
  DELETE_INCIDENCE,
  OPEN_INCIDENCE_FORM,
  CLOSE_INCIDENCE_FORM,
  EDIT_SELECTED_INCIDENCE,
  INCIDENCE_FORM_TOOGLE_LOADING,
  SET_SATISFACTION_LIST,
  ADD_NEW_SATISFACTION,
  UPDATE_SATISFACTION,
  DELETE_SATISFACTION,
  OPEN_SATISFACTION_FORM,
  CLOSE_SATISFACTION_FORM,
  EDIT_SELECTED_SATISFACTION,
  SATISFACTION_FORM_TOOGLE_LOADING,
  ADD_NEW_ALERT,
  SET_ALERT_LIST,
  UPDATE_ALERT,
  DELETE_ALERT,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_SHOPCART_LIST,
  ADD_NEW_SHOPCART,
  UPDATE_SHOPCART,
  DELETE_SHOPCART,
  OPEN_SHOPCART_FORM,
  CLOSE_SHOPCART_FORM,
  EDIT_SELECTED_SHOPCART,
  SHOPCART_FORM_TOOGLE_LOADING,
  SET_ARTICLES_LIST,
  SET_SECURITYSHEET_LIST,
  ADD_NEW_SECURITYSHEET,
  UPDATE_SECURITYSHEET,
  DELETE_SECURITYSHEET,
  OPEN_SECURITYSHEET_FORM,
  CLOSE_SECURITYSHEET_FORM,
  EDIT_SELECTED_SECURITYSHEET,
  SECURITYSHEET_FORM_TOOGLE_LOADING,
  SET_DELIVERYNOTES_LIST,
  SET_INVOICES_LIST,
  SET_DOWNLOADDOCUMENT_LIST,
  OPEN_SIGN_FORM,
  CLOSE_SIGN_FORM,
  SET_REPARTIDORS_LIST,
  SET_REPARTIMENTS_LIST,
  SET_SUGGESTION_LIST,
  ADD_NEW_SUGGESTION,
  UPDATE_SUGGESTION,
  DELETE_SUGGESTION,
  OPEN_SUGGESTION_FORM,
  CLOSE_SUGGESTION_FORM,
  EDIT_SELECTED_SUGGESTION,
  SUGGESTION_FORM_TOOGLE_LOADING,
  SET_ESTABLISHMENTINFO,
  OPEN_ESTABLISHMENTINFO_FORM,
  CLOSE_ESTABLISHMENTINFO_FORM,
  EDIT_SELECTED_ESTABLISHMENTINFO,
  ESTABLISHMENTINFO_FORM_TOOGLE_LOADING,
  SET_TAG_LIST,
  ADD_NEW_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  OPEN_TAG_FORM,
  CLOSE_TAG_FORM,
  EDIT_SELECTED_TAG,
  TAG_FORM_TOOGLE_LOADING,
  SET_EXPENSE_LIST,
  ADD_NEW_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  OPEN_EXPENSE_FORM,
  CLOSE_EXPENSE_FORM,
  EDIT_SELECTED_EXPENSE,
  EXPENSE_FORM_TOOGLE_LOADING,
  SET_OPCUA_LIST,
  ADD_NEW_OPCUA,
  UPDATE_OPCUA,
  DELETE_OPCUA,
  OPEN_OPCUA_FORM,
  CLOSE_OPCUA_FORM,
  EDIT_SELECTED_OPCUA,
  OPCUA_FORM_TOOGLE_LOADING,
  ADD_NEW_TIMEREQUEST,
  SET_TIMEREQUEST_LIST,
  UPDATE_TIMEREQUEST,
  DELETE_TIMEREQUEST,
  OPEN_TIMEREQUEST_FORM,
  CLOSE_TIMEREQUEST_FORM,
  EDIT_SELECTED_TIMEREQUEST,
  TIMEREQUEST_FORM_TOOGLE_LOADING,
  SET_HOLIDAYREQUEST_LIST,
  ADD_NEW_HOLIDAYREQUEST,
  UPDATE_HOLIDAYREQUEST,
  DELETE_HOLIDAYREQUEST,
  OPEN_HOLIDAYREQUEST_FORM,
  CLOSE_HOLIDAYREQUEST_FORM,
  EDIT_SELECTED_HOLIDAYREQUEST,
  HOLIDAYREQUEST_FORM_TOOGLE_LOADING,
  SET_WORKSHIFT_LIST,
  ADD_NEW_WORKSHIFT,
  UPDATE_WORKSHIFT,
  DELETE_WORKSHIFT,
  OPEN_WORKSHIFT_FORM,
  CLOSE_WORKSHIFT_FORM,
  EDIT_SELECTED_WORKSHIFT,
  WORKSHIFT_FORM_TOOGLE_LOADING,
  SET_DOSIFICATIONPRODUCT_LIST,
  ADD_NEW_DOSIFICATIONPRODUCT,
  UPDATE_DOSIFICATIONPRODUCT,
  DELETE_DOSIFICATIONPRODUCT,
  OPEN_DOSIFICATIONPRODUCT_FORM,
  CLOSE_DOSIFICATIONPRODUCT_FORM,
  EDIT_SELECTED_DOSIFICATIONPRODUCT,
  DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING,
  SET_DOSIFICATIONCONFIG_LIST,
  ADD_NEW_DOSIFICATIONCONFIG,
  UPDATE_DOSIFICATIONCONFIG,
  DELETE_DOSIFICATIONCONFIG,
  OPEN_DOSIFICATIONCONFIG_FORM,
  CLOSE_DOSIFICATIONCONFIG_FORM,
  EDIT_SELECTED_DOSIFICATIONCONFIG,
  DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING,
  SET_LEAVE_LIST,
  ADD_NEW_LEAVE,
  UPDATE_LEAVE,
  DELETE_LEAVE,
  OPEN_LEAVE_FORM,
  CLOSE_LEAVE_FORM,
  EDIT_SELECTED_LEAVE,
  LEAVE_FORM_TOOGLE_LOADING,
  ADD_NEW_REASON,
  SET_REASON_LIST,
  UPDATE_REASON,
  DELETE_REASON,
  OPEN_REASON_FORM,
  CLOSE_REASON_FORM,
  EDIT_SELECTED_REASON,
  REASON_FORM_TOOGLE_LOADING,
  SET_EQUIPMENTTYPE_LIST,
  ADD_NEW_EQUIPMENTTYPE,
  UPDATE_EQUIPMENTTYPE,
  DELETE_EQUIPMENTTYPE,
  OPEN_EQUIPMENTTYPE_FORM,
  CLOSE_EQUIPMENTTYPE_FORM,
  EDIT_SELECTED_EQUIPMENTTYPE,
  EQUIPMENTTYPE_FORM_TOOGLE_LOADING,
  SET_PRODUCTS_LIST,
  SET_COMPONENT_LIST,
  ADD_NEW_COMPONENT,
  UPDATE_COMPONENT,
  DELETE_COMPONENT,
  OPEN_COMPONENT_FORM,
  CLOSE_COMPONENT_FORM,
  EDIT_SELECTED_COMPONENT,
  COMPONENT_FORM_TOOGLE_LOADING,
  ADD_NEW_COMPONENTTYPE,
  SET_COMPONENTTYPE_LIST,
  UPDATE_COMPONENTTYPE,
  DELETE_COMPONENTTYPE,
  OPEN_COMPONENTTYPE_FORM,
  CLOSE_COMPONENTTYPE_FORM,
  EDIT_SELECTED_COMPONENTTYPE,
  COMPONENTTYPE_FORM_TOOGLE_LOADING,
  SET_MAINTENANCE_LIST,
  ADD_NEW_MAINTENANCE,
  UPDATE_MAINTENANCE,
  DELETE_MAINTENANCE,
  OPEN_MAINTENANCE_FORM,
  CLOSE_MAINTENANCE_FORM,
  EDIT_SELECTED_MAINTENANCE,
  MAINTENANCE_FORM_TOOGLE_LOADING,
  SET_WORKORDER_LIST,
  ADD_NEW_WORKORDER,
  UPDATE_WORKORDER,
  DELETE_WORKORDER,
  OPEN_WORKORDER_FORM,
  CLOSE_WORKORDER_FORM,
  EDIT_SELECTED_WORKORDER,
  WORKORDER_FORM_TOOGLE_LOADING,
  SET_ROLE_LIST,
  ADD_NEW_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  OPEN_ROLE_FORM,
  CLOSE_ROLE_FORM,
  EDIT_SELECTED_ROLE,
  ROLE_FORM_TOOGLE_LOADING,
  SET_DOSIFICATION_LIST,
  ADD_NEW_DOSIFICATION,
  UPDATE_DOSIFICATION,
  DELETE_DOSIFICATION,
  OPEN_DOSIFICATION_FORM,
  CLOSE_DOSIFICATION_FORM,
  EDIT_SELECTED_DOSIFICATION,
  DOSIFICATION_FORM_TOOGLE_LOADING,
  ADD_NEW_RAW,
  SET_RAW_LIST,
  UPDATE_RAW,
  DELETE_RAW,
  OPEN_RAW_FORM,
  CLOSE_RAW_FORM,
  EDIT_SELECTED_RAW,
  RAW_FORM_TOOGLE_LOADING,
  SET_EPI_LIST,
  ADD_NEW_EPI,
  UPDATE_EPI,
  DELETE_EPI,
  OPEN_EPI_FORM,
  CLOSE_EPI_FORM,
  EDIT_SELECTED_EPI,
  EPI_FORM_TOOGLE_LOADING,
  SET_ALLINSTALATION_LIST,
  SET_ALLEQUIPMENT_LIST,
  THEME,
  SET_NAVCUSTOMER_LIST,
} from "./../../constants";
import { createCrudReducers } from "../../utils/crudGenerator";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  null
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => action.payload,
  false
);

export const users = handleActions(
  {
    [SET_USER_LIST]: (state, action) => action.payload,
    [ADD_NEW_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_USER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_USER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const navUsers = handleActions(
  {
    [SET_NAVUSER_LIST]: (state, action) => action.payload,
  },
  null
);

export const navCustomers = handleActions(
  {
    [SET_NAVCUSTOMER_LIST]: (state, action) => action.payload,
  },
  null
);

export const products = handleActions(
  {
    [SET_PRODUCTS_LIST]: (state, action) => action.payload,
  },
  null
);

export const usersForm = handleActions(
  {
    [OPEN_USERS_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_USERS_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_USER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [USERS_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EQUIPMENTCONFIGURATIONS
export const equipmentConfigurations = handleActions(
  {
    [SET_EQUIPMENTCONFIGURATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_EQUIPMENTCONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EQUIPMENTCONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EQUIPMENTCONFIGURATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const equipmentConfigurationsForm = handleActions(
  {
    [OPEN_EQUIPMENTCONFIGURATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EQUIPMENTCONFIGURATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EQUIPMENTCONFIGURATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// DOWNLOADDOCUMENT
export const downloadDocuments = handleActions(
  {
    [SET_DOWNLOADDOCUMENT_LIST]: (state, action) => action.payload,
  },
  null
);

// INSTALATIONS
export const instalations = handleActions(
  {
    [SET_INSTALATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_INSTALATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_INSTALATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_INSTALATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const instalationsForm = handleActions(
  {
    [OPEN_INSTALATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_INSTALATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_INSTALATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [INSTALATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const signForm = handleActions(
  {
    [OPEN_SIGN_FORM]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CLOSE_SIGN_FORM]: (s, action) => ({
      show: false,
      data: null,
      loading: false,
    }),
  },
  { show: false, data: null, loading: false }
);

// SECURITYSHEETS
export const securitySheets = handleActions(
  {
    [SET_SECURITYSHEET_LIST]: (state, action) => action.payload,
    [ADD_NEW_SECURITYSHEET]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SECURITYSHEET]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SECURITYSHEET]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const securitySheetsForm = handleActions(
  {
    [OPEN_SECURITYSHEET_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SECURITYSHEET_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SECURITYSHEET]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SECURITYSHEET_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ARTICLES
export const articles = handleActions(
  {
    [SET_ARTICLES_LIST]: (state, action) => action.payload,
  },
  null
);

// REPARTIDORS
export const repartidors = handleActions(
  {
    [SET_REPARTIDORS_LIST]: (state, action) => action.payload,
  },
  null
);

// REPARTIMENTS
export const repartiments = handleActions(
  {
    [SET_REPARTIMENTS_LIST]: (state, action) => action.payload,
  },
  null
);

// INVOICES
export const invoices = handleActions(
  {
    [SET_INVOICES_LIST]: (state, action) => action.payload,
  },
  null
);

// DELIVERYNOTES
export const deliveryNotes = handleActions(
  {
    [SET_DELIVERYNOTES_LIST]: (state, action) => action.payload,
  },
  null
);

export const allInstalations = handleActions(
  {
    [SET_ALLINSTALATION_LIST]: (state, action) => action.payload,
  },
  null
);

export const allEquipments = handleActions(
  {
    [SET_ALLEQUIPMENT_LIST]: (state, action) => action.payload,
  },
  null
);

// SHOPCARTS
export const shopCarts = handleActions(
  {
    [SET_SHOPCART_LIST]: (state, action) => action.payload,
    [ADD_NEW_SHOPCART]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SHOPCART]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SHOPCART]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const shopCartsForm = handleActions(
  {
    [OPEN_SHOPCART_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SHOPCART_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SHOPCART]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SHOPCART_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SATISFACTIONS
export const satisfactions = handleActions(
  {
    [SET_SATISFACTION_LIST]: (state, action) => action.payload,
    [ADD_NEW_SATISFACTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SATISFACTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SATISFACTION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const satisfactionsForm = handleActions(
  {
    [OPEN_SATISFACTION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SATISFACTION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SATISFACTION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SATISFACTION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// INCIDENCES
export const incidences = handleActions(
  {
    [SET_INCIDENCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_INCIDENCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_INCIDENCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_INCIDENCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const incidencesForm = handleActions(
  {
    [OPEN_INCIDENCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_INCIDENCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_INCIDENCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [INCIDENCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EQUIPMENTS
export const equipments = handleActions(
  {
    [SET_EQUIPMENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_EQUIPMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EQUIPMENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EQUIPMENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const equipmentsForm = handleActions(
  {
    [OPEN_EQUIPMENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EQUIPMENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EQUIPMENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EQUIPMENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SINGUPS
export const singUps = handleActions(
  {
    [SET_SINGUP_LIST]: (state, action) => action.payload,
    [ADD_NEW_SINGUP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SINGUP]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SINGUP]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const singUpsForm = handleActions(
  {
    [OPEN_SINGUP_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SINGUP_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SINGUP]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SINGUP_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// PROJECTS
export const projects = handleActions(
  {
    [SET_PROJECT_LIST]: (state, action) => action.payload,
    [ADD_NEW_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_PROJECT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_PROJECT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const projectsForm = handleActions(
  {
    [OPEN_PROJECT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_PROJECT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_PROJECT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [PROJECT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// SUGGESTIONS
export const suggestions = handleActions(
  {
    [SET_SUGGESTION_LIST]: (state, action) => action.payload,
    [ADD_NEW_SUGGESTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_SUGGESTION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_SUGGESTION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const suggestionsForm = handleActions(
  {
    [OPEN_SUGGESTION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_SUGGESTION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_SUGGESTION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [SUGGESTION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// WORKORDERS
export const workOrders = handleActions(
  {
    [SET_WORKORDER_LIST]: (state, action) => action.payload,
    [ADD_NEW_WORKORDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_WORKORDER]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_WORKORDER]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const workOrdersForm = handleActions(
  {
    [OPEN_WORKORDER_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_WORKORDER_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_WORKORDER]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [WORKORDER_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TAGS
export const tags = handleActions(
  {
    [SET_TAG_LIST]: (state, action) => action.payload,
    [ADD_NEW_TAG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TAG]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TAG]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const tagsForm = handleActions(
  {
    [OPEN_TAG_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TAG_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TAG]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TAG_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// DOSIFICATIONS
export const dosifications = handleActions(
  {
    [SET_DOSIFICATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_DOSIFICATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_DOSIFICATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_DOSIFICATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const dosificationsForm = handleActions(
  {
    [OPEN_DOSIFICATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_DOSIFICATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_DOSIFICATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [DOSIFICATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// REASONS
export const reasons = handleActions(
  {
    [SET_REASON_LIST]: (state, action) => action.payload,
    [ADD_NEW_REASON]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_REASON]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_REASON]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const reasonsForm = handleActions(
  {
    [OPEN_REASON_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_REASON_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_REASON]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [REASON_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EPIS
export const epis = handleActions(
  {
    [SET_EPI_LIST]: (state, action) => action.payload,
    [ADD_NEW_EPI]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EPI]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EPI]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const episForm = handleActions(
  {
    [OPEN_EPI_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EPI_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EPI]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EPI_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// RAWS
export const raws = handleActions(
  {
    [SET_RAW_LIST]: (state, action) => action.payload,
    [ADD_NEW_RAW]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_RAW]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_RAW]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const rawsForm = handleActions(
  {
    [OPEN_RAW_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_RAW_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_RAW]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [RAW_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ROLES
export const roles = handleActions(
  {
    [SET_ROLE_LIST]: (state, action) => action.payload,
    [ADD_NEW_ROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ROLE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ROLE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const rolesForm = handleActions(
  {
    [OPEN_ROLE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ROLE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ROLE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ROLE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// MAINTENANCES
export const maintenances = handleActions(
  {
    [SET_MAINTENANCE_LIST]: (state, action) => action.payload,
    [ADD_NEW_MAINTENANCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_MAINTENANCE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_MAINTENANCE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const maintenancesForm = handleActions(
  {
    [OPEN_MAINTENANCE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_MAINTENANCE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_MAINTENANCE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [MAINTENANCE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// COMPONENTS
export const components = handleActions(
  {
    [SET_COMPONENT_LIST]: (state, action) => action.payload,
    [ADD_NEW_COMPONENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_COMPONENT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_COMPONENT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const componentsForm = handleActions(
  {
    [OPEN_COMPONENT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_COMPONENT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_COMPONENT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [COMPONENT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// COMPONENTTYPES
export const componentTypes = handleActions(
  {
    [SET_COMPONENTTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_COMPONENTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_COMPONENTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_COMPONENTTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const componentTypesForm = handleActions(
  {
    [OPEN_COMPONENTTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_COMPONENTTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_COMPONENTTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [COMPONENTTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EQUIPMENTTYPES
export const equipmentTypes = handleActions(
  {
    [SET_EQUIPMENTTYPE_LIST]: (state, action) => action.payload,
    [ADD_NEW_EQUIPMENTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EQUIPMENTTYPE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EQUIPMENTTYPE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const equipmentTypesForm = handleActions(
  {
    [OPEN_EQUIPMENTTYPE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EQUIPMENTTYPE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EQUIPMENTTYPE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EQUIPMENTTYPE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// WORKSHIFTS
export const workShifts = handleActions(
  {
    [SET_WORKSHIFT_LIST]: (state, action) => action.payload,
    [ADD_NEW_WORKSHIFT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_WORKSHIFT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_WORKSHIFT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const workShiftsForm = handleActions(
  {
    [OPEN_WORKSHIFT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_WORKSHIFT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_WORKSHIFT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [WORKSHIFT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// LEAVES
export const leaves = handleActions(
  {
    [SET_LEAVE_LIST]: (state, action) => action.payload,
    [ADD_NEW_LEAVE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_LEAVE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_LEAVE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const leavesForm = handleActions(
  {
    [OPEN_LEAVE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_LEAVE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_LEAVE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [LEAVE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// DOSIFICATIONPRODUCTS
export const dosificationProducts = handleActions(
  {
    [SET_DOSIFICATIONPRODUCT_LIST]: (state, action) => action.payload,
    [ADD_NEW_DOSIFICATIONPRODUCT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_DOSIFICATIONPRODUCT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_DOSIFICATIONPRODUCT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const dosificationProductsForm = handleActions(
  {
    [OPEN_DOSIFICATIONPRODUCT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_DOSIFICATIONPRODUCT_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_DOSIFICATIONPRODUCT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// DOSIFICATIONCONFIGS
export const dosificationConfigs = handleActions(
  {
    [SET_DOSIFICATIONCONFIG_LIST]: (state, action) => action.payload,
    [ADD_NEW_DOSIFICATIONCONFIG]: (state, action) => action.payload,
    [UPDATE_DOSIFICATIONCONFIG]: (state, action) => action.payload,
    [DELETE_DOSIFICATIONCONFIG]: (state, action) => action.payload,
  },
  null
);

export const dosificationConfigsForm = handleActions(
  {
    [OPEN_DOSIFICATIONCONFIG_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_DOSIFICATIONCONFIG_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_DOSIFICATIONCONFIG]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// OPCUA
export const OPCUA = handleActions(
  {
    [SET_OPCUA_LIST]: (state, action) => action.payload,
    [ADD_NEW_OPCUA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_OPCUA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_OPCUA]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const OPCUAForm = handleActions(
  {
    [OPEN_OPCUA_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_OPCUA_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_OPCUA]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [OPCUA_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// EXPENSES
export const expenses = handleActions(
  {
    [SET_EXPENSE_LIST]: (state, action) => action.payload,
    [ADD_NEW_EXPENSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_EXPENSE]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_EXPENSE]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const expensesForm = handleActions(
  {
    [OPEN_EXPENSE_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_EXPENSE_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_EXPENSE]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [EXPENSE_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// ESTABLISHMENTINFOS
export const establishmentInfo = handleActions(
  {
    [SET_ESTABLISHMENTINFO]: (state, action) => action.payload,
  },
  null
);

export const establishmentInfoForm = handleActions(
  {
    [OPEN_ESTABLISHMENTINFO_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ESTABLISHMENTINFO_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_ESTABLISHMENTINFO]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ESTABLISHMENTINFO_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// IMPUTHOURS
export const imputHours = handleActions(
  {
    [SET_IMPUTHOUR_LIST]: (state, action) => action.payload,
    [ADD_NEW_IMPUTHOUR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_IMPUTHOUR]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_IMPUTHOUR]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const imputHoursForm = handleActions(
  {
    [OPEN_IMPUTHOUR_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_IMPUTHOUR_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_IMPUTHOUR]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [IMPUTHOUR_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// TIMEREQUESTS
export const timeRequests = handleActions(
  {
    [SET_TIMEREQUEST_LIST]: (state, action) => action.payload,
    [ADD_NEW_TIMEREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_TIMEREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_TIMEREQUEST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const timeRequestsForm = handleActions(
  {
    [OPEN_TIMEREQUEST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_TIMEREQUEST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_TIMEREQUEST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [TIMEREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// HOLIDAYREQUESTS
export const holidayRequests = handleActions(
  {
    [SET_HOLIDAYREQUEST_LIST]: (state, action) => action.payload,
    [ADD_NEW_HOLIDAYREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_HOLIDAYREQUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_HOLIDAYREQUEST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const holidayRequestsForm = handleActions(
  {
    [OPEN_HOLIDAYREQUEST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_HOLIDAYREQUEST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_HOLIDAYREQUEST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [HOLIDAYREQUEST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);
const salesShipmentReducers = createCrudReducers('SALESSHIPMENT');

export const salesShipments = salesShipmentReducers.list;
export const salesShipmentsForm = salesShipmentReducers.form;
